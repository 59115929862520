// @ts-ignore
/* eslint-disable */

import dayjs from '@/utils/dayjs';
import request from './request';

/** 获取当前的用户 GET /api/currentUser */
export async function currentUser(options?: { [key: string]: any }) {
  return request<{
    data: API.CurrentUser;
  }>('/api/currentUser', {
    method: 'GET',
    ...(options || {}),
  });
}

export async function queryTempFiles(id: string, options?: { [key: string]: any }) {
  return request<API.LoginResult>(`/api/tempfile?id=${id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}

export async function getConfig(options?: { [key: string]: any }) {
  return request<{
    data: API.AppConfig;
  }>('/api/config', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 退出登录接口 POST /api/login/outLogin */
export async function outLogin(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/logout', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 登录接口 POST /api/login/account */
export async function login(body: API.LoginParams, options?: { [key: string]: any }) {
  return request<API.LoginResult>('/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 GET /api/notices */
export async function getNotices(options?: { [key: string]: any }) {
  return request<API.NoticeIconList>('/api/notices', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取订单列表 GET /api/orders */
export async function orders(params: any, options?: { [key: string]: any }) {
  return request<API.OrderList>('/api/orders', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

export async function pendingOrders(params: any, options?: { [key: string]: any }) {
  return request<API.OrderList>('/api/pendingOrders', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取订单列表 GET /api/orders */
export async function expressOrders(params: any, options?: { [key: string]: any }) {
  return request<API.OrderList>('/api/express/orders', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取订单列表 GET /api/orders */
export async function storeOrders(params: any, options?: { [key: string]: any }) {
  return request<API.OrderList>('/api/store/orders', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

export async function transactions(params: any, options?: { [key: string]: any }) {
  return request<API.OrderList>('/api/transactions', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

export async function queryStoretransactions(params: any, options?: { [key: string]: any }) {
  return request<API.OrderList>('/api/store/transactions', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

export async function updateOrderStore(
  id: number,
  body: API.UpdateOrderStoreParams,
  options?: { [key: string]: any },
) {
  return request<API.LoginResult>(`/api/updateOrderStore?id=${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function updateOrderExpress(
  id: number,
  body: API.UpdateOrderExpressParams,
  options?: { [key: string]: any },
) {
  return request<API.LoginResult>(`/api/updateOrderExpress?id=${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 创建订单接口 POST /api/login/account */
export async function createOrder(body: API.CreateOrderParams, options?: { [key: string]: any }) {
  return request<API.LoginResult>('/api/createOrder', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改订单接口 POST /api/login/account */
export async function updateOrder(
  id: number,
  body: API.UpdateOrderParams,
  options?: { [key: string]: any },
) {
  return request<API.LoginResult>(`/api/updateOrder?id=${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function addTag(body: any, options?: { [key: string]: any }) {
  return request<API.LoginResult>(`/api/addTag`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function inviteAssitant(body: any, options?: { [key: string]: any }) {
  return request<API.LoginResult>(`/api/inviteAssitant`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
/** 修改订单接口 POST /api/login/account */
export async function createOrderDelivery(
  body: API.UpdateOrderStoreParams,
  options?: { [key: string]: any },
) {
  return request<API.LoginResult>(`/api/createOrderDelivery`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function takeOrder(id: number, options?: { [key: string]: any }) {
  return request<API.LoginResult>(`/api/takeOrder?id=${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}

export async function approveOrder(id: number, options?: { [key: string]: any }) {
  return request<API.LoginResult>(`/api/approveOrder?id=${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}

export async function updateOrderProduct(
  id: number,
  body: API.UpdateOrderProductParams,
  options?: { [key: string]: any },
) {
  return request<API.LoginResult>(`/api/updateOrderProduct?id=${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function addOrderProduct(
  body: API.UpdateOrderProductParams,
  options?: { [key: string]: any },
) {
  return request<API.LoginResult>(`/api/addOrderProduct`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function deleteOrderProduct(id: number, options?: { [key: string]: any }) {
  return request<API.LoginResult>(`/api/deleteOrderProduct?id=${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}

export async function createComment(
  body: API.CreateCommentParams,
  options?: { [key: string]: any },
) {
  return request<API.LoginResult>(`/api/comment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function createTransaction(
  body: API.CreateTransactionParams,
  options?: { [key: string]: any },
) {
  return request<API.LoginResult>('/api/createTransaction', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function updateTransaction(
  id: number,
  body: API.CreateTransactionParams,
  options?: { [key: string]: any },
) {
  if (!body.payment_time) {
    delete body.payment_time;
  } else {
    body.payment_time = dayjs(new Date(body.payment_time)).format('YYYY-MM-DD 00:00:00');
  }

  return request<API.LoginResult>(`/api/updateTransaction?id=${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function deleteTransaction(id: number, options?: { [key: string]: any }) {
  return request<API.LoginResult>(`/api/deleteTransaction?id=${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}
