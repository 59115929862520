// @ts-ignore
/* eslint-disable */
import { extend } from 'umi-request';
import { getQuery } from '../utils/index';

const request = extend({
  credentials: 'include', // 默认请求是否带上cookie
});

request.interceptors.request.use((url, options) => {
  
  // now in options.headers we have all provided headers and return them
  // but umi-request just ignore headers and don't add them to the request
  const config = {
    url,
    options: {
      ...options,
      headers: {
        ...options.headers,
        'token': String(getQuery().token ?? ''),
      }
    },
  };
  return config;
});

export default request;