import { isNB } from './utils/index';
/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(initialState: { currentUser?: API.CurrentUser | undefined }) {
  const { currentUser } = initialState || {};
  return {
    canViewDetail: currentUser && currentUser.role <=3,
    canAdminExtra: currentUser && currentUser.role === 1 && currentUser.extra !== 2, 
    canAdmin: currentUser && currentUser.role === 1,
    canBusiness: currentUser && currentUser.role < 3,
    canAssistant: currentUser && currentUser.role === 3,
    canExpress: currentUser && currentUser.role === 4,
    canStore: currentUser && currentUser.role === 5,
    canAnhuiStore: currentUser &&currentUser.name === '安徽蒙城不锈钢生产部',
    canTransact: (currentUser && currentUser.role < 4) || isNB(),
  };
}
