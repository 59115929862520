const isNB = String(process.env.NB) === '1';
const isHZ = String(process.env.HZ) === '1';
let name = '浙江创象新型材料有限公司';

if (isHZ) {
  name = '湖州创象实业科技有限公司'
} else if (isNB) {
  name = '宁波创象不锈钢制品有限公司'
}

export const companyName = name;