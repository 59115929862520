import BigNumber from 'bignumber.js';

export const ensureNumber = (value?: number): number  => {
    if (typeof value === 'undefined') {
        return 0;
    }
    return value;
}

export const formatNumber = (value: number | BigNumber, fixed: number = 2) : string => {
    if (!value) {
        console.warn(`value is ${value}`);
        value = 0;
    }
    return (parseFloat(value.toFixed(fixed)) || 0 ).toLocaleString();
}