import { OrderKey } from '@/const';
import types from '@/gen_code/types';
import BigNumber from 'bignumber.js';
import { debounce } from 'debounce';
import md5 from 'js-md5';
import queryString from 'query-string';
import dayjs from './dayjs';
import { ensureNumber } from './number';

export function ensureFloat(s: string): number {
  return parseFloat(s.toString().replace(/,/g, '')) || 0;
}

let base_url = window['publicPath'] || `https://unpkg.com/chuangxiang_assets@1.0.20230624035509`;
// const base_url = `https://unpkg.com/chuangxiang_assets@1.0.20230624035509`;
if (base_url.endsWith('/')) {
  base_url = base_url.slice(0, -1);
}

export function getCDNUrl(path: string): string {
  if (base_url === '/') {
    return path;
  }
  return base_url + (path.startsWith('/') ? path : `/` + path);
}

export function getContractUrl(contract_type: number, id: number, need_sign: boolean = false) {
  let path = `/contract/view`;
  //   if (contract_type === 1) {
  //     path = `/contract/stainless_steel_view`;
  //   }
  return `${path}?id=${id}&need_sign=${need_sign ? 1 : 0}`;
}

export const fetcher = (url: any) =>
  fetch(url, {
    headers: {
      token: String(getQuery().token ?? ''),
    },
  }).then((res) => res.json());

export const formatDate = (date: string, showTime: boolean = true): string => {
  if (!date) {
    return '';
  }

  if (!showTime) {
    return dayjs.utc(date).local().format('YYYY-MM-DD');
  }
  return dayjs.utc(date).local().format('YYYY-MM-DD HH:mm::ss');
};

export const getContractDate = (): string => {
  return dayjs().local().format('YYYY年MM月DD日');
};

export const getCurrentDateTime = (showTime: boolean = false): string => {
  if (!showTime) {
    return dayjs().local().format('YYYY-MM-DD');
  }
  return dayjs().local().format('YYYY-MM-DD HH:mm::ss');
};

export const getStartDateTime = (): string => {
  return dayjs().local().format('YYYY-MM-01 00:00::00');
};

export const formatFloat = (s: string | number) => {
  if (typeof s === 'string') {
    s = ensureFloat(s);
  }
  const n = (s || 0) * 100;
  return Math.round(n) / 100;
};

export const getOrder = () => {
  let oldValues: any = {};
  try {
    oldValues = JSON.parse(String(localStorage.getItem(OrderKey)));
    if (oldValues && oldValues.product_list) {
      oldValues.product_list = oldValues.product_list.filter((item: any) => {
        return item.id > 0 && item.name;
      });
    }
  } catch (err) {}
  return oldValues;
};

let currentOrder = {};

export const clearOrder = () => {
  localStorage.removeItem(OrderKey);
  currentOrder = {};
};

const save = debounce((values: any) => {
  localStorage.setItem(OrderKey, JSON.stringify(values, null, 2));
}, 1000);

export const saveOrder = (values: any) => {
  currentOrder = {
    ...currentOrder,
    ...values,
  };
  save(currentOrder);
};

export const handleUpload = (items: any[]): string | undefined => {
  if (items && items.length) {
    return items
      .map((item: any) => {
        if (item.oss_id) {
          return item.id;
        }
        const response = item.response;
        return response.data?.file_id || response.file_id;
      })
      .join(',');
  }
  return undefined;
};

export const getUrl = (params: any, withHost = true): string => {
  const parsed = queryString.parse(location.search);
  const query = {
    ...parsed,
    ...params,
  };

  const path = `${location.pathname}?${queryString.stringify(query)}`;
  if (withHost) {
    return `${location.protocol}//${location.host}${path}`;
  }
  return path;
};

export const getQuery = () => {
  return queryString.parse(location.search) || {};
};

export function isNB(): boolean {
  const hostname = window.location.hostname;
  return ['dev.zjchuangxiang.com', 'nb.zjchuangxiang.com'].includes(hostname);
}

export function isHZ(): boolean {
  const hostname = window.location.hostname;
  return ['hz.bestchuangxiang.com'].includes(hostname);
}

export function getCompanyName(): string {
  let companyName = '浙江创象新型材料有限公司';

  if (isHZ()) {
    companyName = '湖州创象实业科技有限公司';
  } else if (isNB()) {
    companyName = '安徽创象不锈钢制品有限公司';
  }
  return companyName;
}

export function getUserAvatar(userName?: string): string {
  return `https://api.multiavatar.com/${md5(userName + 'cx')}.png`;
}

export function calculateTotalAmount(products: types.order_product.Model[]): {
  totalAmount: string;
  totalWeight: string;
} {
  let totalAmount = new BigNumber(0);
  let totalWeight = new BigNumber(0);
  for (let product of products) {
    totalAmount = totalAmount.plus(
      new BigNumber(product.price).times(product.amount).plus(ensureNumber(product.delivery_fees)),
    );
    totalWeight = totalWeight.plus(new BigNumber(product.weight).times(product.amount));
  }
  return {
    totalAmount: totalAmount.toFixed(2),
    totalWeight: totalWeight.toFixed(2),
  };
}

export function evaluate(expression: string | undefined): number {
  if (!expression) {
    return 0;
  }
  try {
    // eslint-disable-next-line no-eval
    const r = eval(expression);
    if (r.toString().includes('.')) {
      return parseFloat(r.toFixed(2));
    }
    return r;
  } catch (err) {
    return 0;
  }
}
