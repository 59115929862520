const dayjs = require('dayjs');
const weekday = require('dayjs/plugin/weekday');
const advancedFormat = require('dayjs/plugin/advancedFormat');
const customParseFormat = require('dayjs/plugin/customParseFormat');
const weekOfYear = require('dayjs/plugin/weekOfYear');
const weekYear = require('dayjs/plugin/weekYear');
const localeData = require('dayjs/plugin/localeData');
const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

console.log(dayjs().weekday);

export default dayjs;
