import Footer from '@/components/Footer';
import RightContent from '@/components/right_content';
import type { Settings as LayoutSettings } from '@ant-design/pro-components';
import type { RunTimeLayoutConfig } from '@umijs/max';
import { history } from '@umijs/max';
import defaultSettings from '../config/defaultSettings';
import { errorConfig } from './requestErrorConfig';
import { currentUser as queryCurrentUser } from './services/ant-design-pro/api';
import { getConfig } from './services/api';

const loginPath = '/user/login';
const uploadPath = '/qrcode/upload';

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  appConfig: API.AppConfig;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
}> {
  console.log(`history.location.pathname = ${history.location.pathname}`);

  if (history.location.pathname === uploadPath) {
    return {
      settings: defaultSettings as any,
    } as any;
  }

  const fetchUserInfo = async () => {
    try {
      const msg: any = await queryCurrentUser();
      return msg.data;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };

  const appConfig: any = await getConfig();

  const expressMap = {};
  const storeMap = {};
  for (let item of appConfig.express) {
    if (item.name.includes('湖州')) {
      continue;
    }
    expressMap[item.company_id] = {
      ...item,
      id: item.company_id,
    };
  }

  for (let item of appConfig.store) {
    if (item.name.includes('湖州')) {
      continue;
    }
    storeMap[item.company_id] = {
      ...item,
      id: item.company_id,
    };
  }

  appConfig.express = Object.keys(expressMap).map((key) => {
    return expressMap[key];
  });

  appConfig.store = Object.keys(storeMap).map((key) => {
    return storeMap[key];
  });

  appConfig.storeMap = storeMap;

  // 如果是登录页面，不执行
  if (history.location.pathname !== loginPath) {
    const currentUser = await fetchUserInfo();

    const pathname = history.location.pathname;
    if (pathname === '/order/detail') {
      // 物流
      if (currentUser.role === 4) {
        window.location.href = `/order/transport/detail${location.search}`;
      } else if (currentUser.role === 5) {
        // 仓库
        window.location.href = `/order/store/detail${location.search}`;
      }
    } else if (pathname === '/order/transport/detail') {
      if (currentUser.role <= 3) {
        window.location.href = `/order/detail${location.search}&tab=transport`;
      }
    } else if (pathname === '/order/store/detail') {
      if (currentUser.role <= 3) {
        window.location.href = `/order/detail${location.search}&tab=store`;
      }
    }
    return {
      fetchUserInfo,
      currentUser,
      appConfig,
      settings: defaultSettings as any,
    };
  }

  return {
    appConfig,
    fetchUserInfo,
    settings: defaultSettings as any,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {
  return {
    waterMarkProps: {
      content: '创象 ' + initialState?.currentUser?.name.split(/[(（]/)[0],
    },

    rightContentRender: () => <RightContent />,

    footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      if (!initialState?.currentUser && location.pathname !== loginPath) {
        history.push(loginPath);
      }
    },
    layoutBgImgList: [
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/D2LWSqNny4sAAAAAAAAAAAAAFl94AQBr',
        left: 85,
        bottom: 100,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/C2TWRpJpiC0AAAAAAAAAAAAAFl94AQBr',
        bottom: -68,
        right: -45,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/F6vSTbj8KpYAAAAAAAAAAAAAFl94AQBr',
        bottom: 0,
        left: 0,
        width: '331px',
      },
    ],
    links: [],
    menuHeaderRender: undefined,
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    // 增加一个 loading 的状态
    childrenRender: (children) => {
      // if (initialState?.loading) return <PageLoading />;
      return <>{children}</>;
    },
    ...initialState?.settings,
  };
};
/**
 * @name request 配置，可以配置错误处理
 * 它基于 axios 和 ahooks 的 useRequest 提供了一套统一的网络请求和错误处理方案。
 * @doc https://umijs.org/docs/max/request#配置
 */
export const request = {
  ...errorConfig,
};
